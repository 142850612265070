/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { color, space } from 'styled-system';
import { useSelector } from 'react-redux';
import selectRevenue from 'redux-api/reselect/revenue';
import selectProfile from 'redux-api/reselect/profile';
import Card from 'theme/card';
import Text from 'theme/text';
import ChartIcon from 'theme/icons/chart';
import ContentLoader from 'theme/content-loader';
import translations from 'translations';

const Content = styled.div`
  display: flex;
  flex-direction: column;

  & > .card-percent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: ${themeGet('space.s')}px;

    & > svg {
      ${color}
    }
  }
`;

const Value = styled.div`
  ${color}
  ${space}

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

function TemporaryRevenue({ queriedKey }) {
  const { languageCode } = useSelector((state) => state.config);
  const temporaryRevenue = useSelector((state) => selectRevenue(state, queriedKey));
  const {
    payload: { company },
  } = useSelector(selectProfile);

  return (
    <Card width="33.33%" bg="grey_100" borderColor="primary_400" pt="l" pb="l">
      <Content color="primary_400">
        <div className="card-percent">
          <ChartIcon
            style={{
              width: ['24px', '24px', '24px', '48px'],
            }}
          />
          <Value pl="s">
            <Text
              fontSize={['h2', 'h2', 'h2', 'h2Large']}
              lineHeight={['h2', 'h2', 'h2', 'h2Large']}
              fontWeight="bold"
              color={
                temporaryRevenue.percent > 0
                  ? 'lime_400'
                  : temporaryRevenue.percent < 0
                    ? 'red_400'
                    : 'grey_600'
              }
            >
              {`${temporaryRevenue.percent > 0 ? '+' : temporaryRevenue.percent < 0 ? '-' : ''
                } ${Math.abs(temporaryRevenue.percent)}%`}
            </Text>
          </Value>
        </div>
        <Text
          fontSize={['display', 'display', 'display', 'h2']}
          lineHeight={['display', 'display', 'display', 'h2']}
          fontWeight="bold"
          color="grey_600"
          textAlign="center"
          mb="xxs"
        >
          {translations[languageCode]['temporary revenues']}
        </Text>
        <Text
          fontSize={['h2', 'h2', 'h2', 'h3Large']}
          lineHeight={['h2', 'h2', 'h2', 'h3Large']}
          fontWeight={['medium', 'medium', 'medium', 'normal']}
          color="grey_600"
          textAlign="center"
        >
          {temporaryRevenue.value === null ? (
            <ContentLoader
              width={50}
              height={16}
              lines={[
                {
                  x: 0,
                  y: 5,
                  rx: 5,
                  ry: 5,
                  width: 50,
                  height: 12,
                },
              ]}
            />
          ) : (
            temporaryRevenue.value
          )}
        </Text>
        {company && company.code === 'SL_US' && (
          <a href="https://www.streetlib.com/pricing/" target="_blank" rel="noopener noreferrer">
            <Text
              fontSize={['small']}
              lineHeight={['h2']}
              fontWeight="bold"
              color="primary_300"
              textAlign="center"
              mb="xxs"
            >
              {translations[languageCode].earn_more_revenues}
            </Text>
          </a>
        )}
      </Content>
    </Card>
  );
}

export default TemporaryRevenue;
